import { createSlice } from '@reduxjs/toolkit';

const generalSlice = createSlice({
  name: 'general',
  initialState: {
    activeNav: "chats",
    theme: {},
    backofficeColors: {},
    settings: [],
    sidebarOpen: false,
    isNotificationSound: localStorage.getItem("isNotificationSound") === "false" ? false : true,
    isSocketConnecting: false
  },
  reducers: {
    setSettings(state, action){
      state.settings = action.payload;
    },
    setActiveNav(state, action){
      state.activeNav = action.payload;
    },
    setTheme(state, action) {
      state.theme = action.payload
    },
    setBackofficeColors(state, action) {
      state.backofficeColors = action.payload
    },
    setSidebarOpen(state, action) {
      state.sidebarOpen = action.payload
    },
    setNotificationSound(state, action) {
      localStorage.setItem("isNotificationSound", action.payload)
      state.isNotificationSound = action.payload
    },
    setSocketConnecting(state, action) {
      state.isSocketConnecting = action.payload
    },
  },
});

export const selectSidebarOpen = state => state.generalData.sidebarOpen
export const selectSettings = state => state.generalData.settings
export const selectActiveNav = state => state.generalData.activeNav
export const selectTheme = state => state.generalData.theme
export const selectBackofficeColors = state => state.generalData.backofficeColors
export const selectNotificationSound = state => state.generalData.isNotificationSound
export const selectSocketConnecting = state => state.generalData.isSocketConnecting

export const {setActiveNav, setTheme, setBackofficeColors, setSettings, setSidebarOpen, setNotificationSound, setSocketConnecting} = generalSlice.actions;

export default generalSlice.reducer;
